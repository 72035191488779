import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Box } from "rebass";
import styled from "styled-components";

import { AutoRow, RowBetween } from "../components/Row";
import { AutoColumn } from "../components/Column";
import PairList from "../components/PairList";
import TopTokenList from "../components/TokenList";
import TxnList from "../components/TxnList";
import GlobalChart from "../components/GlobalChart";
import Search from "../components/Search";
import GlobalStats from "../components/GlobalStats";

import {
  useGlobalData,
  useGlobalTransactions,
  useGlobalChartData,
} from "../contexts/GlobalData";
import { useAllPairData } from "../contexts/PairData";
import { useMedia } from "react-use";
import Panel from "../components/Panel";
import { useAllTokenData } from "../contexts/TokenData";
import { formattedNum, formattedPercent } from "../utils";
import { TYPE, ThemedBackground } from "../Theme";
import { CustomLink } from "../components/Link";

import { PageWrapper, ContentWrapper } from "../components";
import CheckBox from "../components/Checkbox";
import FeesList from "../components/FeesList";
import QuestionHelper from "../components/QuestionHelper";
import { useVersion } from "../contexts/Application";

import { Button } from "@mantine/core";
import { FEE_SPLIT_TELOS, FEE_SPLIT_MANTLE } from '../constants'

const ListOptions = styled(AutoRow)`
  height: 40px;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;

  @media screen and (max-width: 640px) {
    font-size: 1rem;
  }
`;

const GridRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 6px;
  align-items: start;
  justify-content: space-between;
`;



function FeesPage() {
  // get data for lists and totals
  const allPairs = useAllPairData();
  const allTokens = useAllTokenData();
  const transactions = useGlobalTransactions();
  const { oneDayFeeUSD, oneDayFeeChange } = useGlobalData();

  // global historical data
  const [dailyData, weeklyData] = useGlobalChartData();
  const [feesData, setFeesData] = useState();
  const [feesDataMonthly, setFeesDataMonthly] = useState();
  
  const { versionLabel } = useVersion();
  const FEE_SPLIT = versionLabel === "telos" ? FEE_SPLIT_TELOS : FEE_SPLIT_MANTLE;

  useEffect(() => {
    const feeData = dailyData
      .filter((item) => item.date > 1697414400)
      .map((item) => {
        return {
          timestamp: item.date,
          date: new Date(item.date * 1000).toDateString(),
          month: new Date(item.date * 1000).toLocaleString("default", {
            month: "long",
          }),
          year: new Date(item.date * 1000).getUTCFullYear(),
          feesUSD: item.feesUSD * 1,
          feesUSDBuyback: item.feesUSD * FEE_SPLIT.BUYBACK,
          feesUSDSwapsicle: item.feesUSD * FEE_SPLIT.PLATFORM,
          feesUSDICV: item.feesUSD * FEE_SPLIT.ICECREAMVAN,
          feesUSDZV: item.feesUSD * FEE_SPLIT.ZOMBIEVAN,
        };
      });
    setFeesData(feeData);

    // Group feeData by month and year and calculate totals
    const groupedData = feeData.reduce((result, item) => {
      const key = `${item.year}${item.month}`;
      if (!result[key]) {
        result[key] = {
          timestamp: item.timestamp,
          month: item.month,
          year: item.year,
          feesUSD: 0,
          feesUSDBuyback: 0,
          feesUSDSwapsicle: 0,
          feesUSDICV: 0,
          feesUSDZV: 0,
        };
      }

      result[key].feesUSD += item.feesUSD;
      result[key].feesUSDBuyback += item.feesUSDBuyback;
      result[key].feesUSDSwapsicle += item.feesUSDSwapsicle;
      result[key].feesUSDICV += item.feesUSDICV;
      result[key].feesUSDZV += item.feesUSDZV;

      return result;
    }, {});

    setFeesDataMonthly(Object.values(groupedData));
  }, [dailyData]);

  // breakpoints
  const below800 = useMedia("(max-width: 800px)");

  // scrolling refs
  useEffect(() => {
    document.querySelector("body").scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);

  // for tracked data on pairs
  const [useTracked, setUseTracked] = useState(true);

  return (
    <PageWrapper>
      <ThemedBackground backgroundColor={"#0b1221"} />
      <ContentWrapper>
        <div>
          <AutoColumn
            gap="24px"
            style={{ paddingBottom: below800 ? "0" : "24px" }}
          >
            <TYPE.largeHeader>
              {below800 ? "Fees Summary" : "Fees Summary"}
            </TYPE.largeHeader>
            <Search />
            <GlobalStats />
          </AutoColumn>
          {below800 && ( // mobile card
            <Box mb={20}>
              <Panel>
                <Box>
                  <AutoColumn gap="36px">
                    <AutoColumn gap="20px">
                      <RowBetween>
                        <TYPE.main>Fees (24hrs)</TYPE.main>
                        <div />
                      </RowBetween>
                      <RowBetween align="flex-end">
                        <TYPE.main
                          fontSize={"1.5rem"}
                          lineHeight={1}
                          fontWeight={600}
                        >
                          {oneDayFeeUSD
                            ? formattedNum(oneDayFeeUSD, true)
                            : "-"}
                        </TYPE.main>
                        <TYPE.main fontSize={12}>
                          {oneDayFeeChange
                            ? formattedPercent(oneDayFeeChange)
                            : "-"}
                        </TYPE.main>
                      </RowBetween>
                    </AutoColumn>
                  </AutoColumn>
                </Box>
              </Panel>
            </Box>
          )}
          {!below800 && (
            <>
              <h2 style={{ color: "white" }}>Fees</h2>
              <GridRow style={{ marginBottom: "20px" }}>
                <Panel style={{ height: "100%", minHeight: "300px" }}>
                  <GlobalChart display="fees" />
                </Panel>
              </GridRow>
            </>
          )}
          {/* {!below800 && (
            <>
              <h2 style={{ color: "white" }}>Fees Breakdown</h2>
              <GridRow style={{ marginBottom: "20px" }}>
                <Panel style={{ height: "100%", minHeight: "300px" }}>
                  <GlobalChart display="fees_buyback" />
                </Panel>
                <Panel style={{ height: "100%", minHeight: "300px" }}>
                  <GlobalChart display="fees_zv" />
                </Panel>
              </GridRow>
              <GridRow style={{ marginBottom: "20px" }}>
                <Panel style={{ height: "100%", minHeight: "300px" }}>
                  <GlobalChart display="fees_icv" />
                </Panel>
                <Panel style={{ height: "100%", minHeight: "300px" }}>
                  <GlobalChart display="fees_swapsicle" />
                </Panel>
              </GridRow>
            </>
          )} */}
          {!below800 && (
            <>
              <h2 style={{ color: "white" }}>Fees By Month</h2>
              <Panel
                style={{
                  marginTop: "6px",
                }}
              >
                <FeesList feesByDay={feesDataMonthly}></FeesList>
              </Panel>
            </>
          )}
          {!below800 && (
            <>
              <h2 style={{ color: "white" }}>Fees By Day</h2>
              <Panel
                style={{
                  marginTop: "6px",
                }}
              >
                <FeesList feesByDay={feesData}></FeesList>
              </Panel>
            </>
          )}
          {below800 && (
            <AutoColumn style={{ marginTop: "6px" }} gap="24px">
              <Panel style={{ height: "100%", minHeight: "300px" }}>
                <GlobalChart display="fees" />
              </Panel>
            </AutoColumn>
          )}
          <ListOptions
            gap="10px"
            style={{ marginTop: "2rem", marginBottom: ".5rem" }}
          ></ListOptions>
        </div>
      </ContentWrapper>
    </PageWrapper>
  );
}

export default withRouter(FeesPage);
