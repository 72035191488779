import React, { useState, useEffect, useMemo } from "react";

import { withRouter } from "react-router-dom";
import { Table, Button } from "@mantine/core";

function DefrostByDayList(redeems) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  //console.log("DefrostByDayList", redeems);

  if (!redeems.redeems) {
    return <div style={{ color: "white" }}>Ice data not available</div>;
  }

  const totalItems = Object.values(redeems.redeems).length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Calculate the starting and ending index for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Sort feesByDay by element.timestamp in descending order
  const sortedRedeems = Object.values(redeems.redeems)
    .sort((a, b) => b.sortKey - a.sortKey);

  // Extract the items for the current page using slice on the sorted array
  const currentItems = sortedRedeems.slice(startIndex, endIndex);

  const rows = currentItems.map((element) => (
    <tr key={element.sortKey}>
      <td style={{ color: "white" }}>{element.displayDate}</td>
      <td style={{ color: "white" }}>
        {Number(Number(element.totalAmount).toFixed(2)).toLocaleString()}
      </td>
      <td style={{ color: "white" }}>
        {Number(Number(element.totalAmountBurn).toFixed(2)).toLocaleString()}
      </td>
      <td style={{ color: "white" }}>
        {(Number(Number( Number(element.totalAmountBurn) / (Number(element.totalAmountBurn)+Number(element.totalAmount)))*100).toFixed(1)).toLocaleString() + '%'}
      </td>
    </tr>
  ));

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th style={{ color: "white" }}>Day</th>
            <th style={{ color: "white" }}>Amount (SLUSH)</th>
            <th style={{ color: "white" }}>Burn (SLUSH)</th>
            <th style={{ color: "white" }}>Burn (%)</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      {totalItems && totalItems > itemsPerPage ? (
        <>
          <div style={{ marginTop: "10px" }}>
            <Button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              style={{
                marginRight: "10px",
                color: "white",
                backgroundColor: "#228be6",
              }}
            >
              Previous Page
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{ color: "white", backgroundColor: "#228be6" }}
            >
              Next Page
            </Button>
          </div>
          <div style={{ color: "white", marginTop: "20px" }}>
            Showing {startIndex + 1} to {endIndex} of {totalItems} entries
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default withRouter(DefrostByDayList);
