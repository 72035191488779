//TODO: change
export const FACTORY_ADDRESS = '0x6EcCab422D763aC031210895C81787E87B43A652'

export const WETH = {
  mantle: '0x78c1b0c915c4faa5fffa6cabf0219da63d7f4cb8',
  mantleTestnet: '0x6e2542afc68a1697feb2810437df9409d3b93493',
  telos: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
  telosTestnet: '0x6e2542afc68a1697feb2810437df9409d3b93493',
}

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  THREE_MONTHS: '3 months',
  YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = ['https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json']

// hide from overview list
export const TOKEN_BLACKLIST = [
  '0xdea0102f2d45a944a720a1cdef87ec8366ea04f5',
  '0x3c393ed453c1017315d4401f6097ea4b944c06ac',
  '0x6e0aa9718c56ef5d19ccf57955284c7cd95737be',
  '0x4c89b40ea5408786e158b60c2711c8d28e7ad32d',
  '0xe4ef5a727ef598722bddc8c6ec33a31258d6a42f',
  '0xfd3b077dc97915ab3a49db1b1021c13ad55751dc',
  '0x0bbf5349d884d135a907040187e0f4e04d05129c',
  '0x3d5f8fb7e5de6bf2c1944d24c94ef48b84ad13c6',
  '0x556ae63bfb80c608589a5c78b456b2fa9145a0a3',
  '0xf3a61c7d53442ac00a3a99761c5839f9109f2193',
  '0x7df4eaa13fba3aa7ec3b77b412566e5204e1539a',
  '0x0fd72ba4e27ba45da0a2a1b45b395a2994751e96',
  '0xaa348cde30eca12ac31d21c3007f06d4c8e577ce',
  '0xd7d5d35883d46d77592d3bff7f1d2414eae01602',
  '0x97444f7a914c0ec57094d81a2f3ec5100691898a',
  '0x90e805ce96b7ce8aa1dc9b01dc19a1c60e9aa777'
]

// pair blacklist
export const PAIR_BLACKLIST = [
  '0x8c1c4da6754cfbc37e09620ef2774fe59e7bba88',
  '0xce79b02ea60e97674f355ef47b250f20f8554878',
  '0x3f2c3eb5e8c1fac4b06ea48345e0769a2e9abaf2'
]

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = []

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or MNT pairings.'

// tokens that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES = [
  '0x26519b547416e4f53f3a4b05b95ef859c3bd89fe',
  '0xf6c7197eaff13002a7b2a2e835a7bb6969a4b026',
  '0x46622913ce40c54ec14857f72968d4baaf963947',
  '0x0f8d6953f58c0dd38077495aca64cbd1c76b7501',
]

export const FEE_SPLIT_TELOS = {
  ICECREAMVAN: 0.10,
  ZOMBIEVAN: 0.05,
  BUYBACK: 0.225,
  PLATFORM: 0.05,
};

export const FEE_SPLIT_MANTLE = {
  ICECREAMVAN: 0.133333,
  ZOMBIEVAN: 0.06666666,
  BUYBACK: 0.225,
  PLATFORM: 0,
};

export const MAXIMUM_TVL = 100000000 // hide data with TVL over this amount