import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { AutoRow } from "../components/Row";
import { AutoColumn } from "../components/Column";
import Search from "../components/Search";
import GlobalStats from "../components/GlobalStats";

import {
  useIce,
} from "../contexts/GlobalData";
import { useMedia } from "react-use";
import Panel from "../components/Panel";
import { TYPE, ThemedBackground } from "../Theme";

import { PageWrapper, ContentWrapper } from "../components";
import DefrostList from "../components/DefrostList";
import ConvertList from "../components/ConvertList";
import DefrostCancelList from "../components/DefrostCancelList";
import DefrostFinalizeList from "../components/DefrostFinalizeList";
import DefrostByMonthList from "../components/DefrostByMonthList";
import DefrostByDayList from "../components/DefrostByDayList";

const GridRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 6px;
  align-items: start;
  justify-content: space-between;
`;

function IcePage() {
  // global historical data
  const iceData = useIce();

  // breakpoints
  const below800 = useMedia("(max-width: 800px)");

  // scrolling refs
  useEffect(() => {
    document.querySelector("body").scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);

  return (
    <PageWrapper>
      <ThemedBackground backgroundColor={"#0b1221"} />
      <ContentWrapper>
        <div>
          <AutoColumn
            gap="24px"
            style={{ paddingBottom: below800 ? "0" : "24px" }}
          >
            <TYPE.largeHeader>
              {below800 ? "Fees Summary" : "Fees Summary"}
            </TYPE.largeHeader>
            <Search />
            <GlobalStats />
          </AutoColumn>
          <>
            <h2 style={{ color: "white" }}>Finalized Defrosts</h2>
            <Panel style={{ marginTop: "6px" }}>
              <DefrostFinalizeList finalizeRedeems={iceData?.finalizeRedeems}></DefrostFinalizeList>
            </Panel>
          </>
          <>
            <h2 style={{ color: "white" }}>Submitted Defrosts</h2>
            <Panel style={{ marginTop: "6px" }}>
              <DefrostList redeems={iceData?.redeems}></DefrostList>
            </Panel>
          </>
          <GridRow>
            <div>
              <h2 style={{ color: "white" }}>ICE Freezes</h2>
              <Panel style={{ marginTop: "6px" }}>
                <ConvertList converts={iceData?.converts}></ConvertList>
              </Panel>
            </div>
            <div>
              <h2 style={{ color: "white" }}>Defrosts Cancelled</h2>
              <Panel style={{ marginTop: "6px" }}>
                <DefrostCancelList cancelRedeems={iceData?.cancelRedeems}></DefrostCancelList>
              </Panel>
            </div>
          </GridRow>
          <GridRow>
            <div>
              <h2 style={{ color: "white" }}>ICE Defrosts by Month</h2>
              <Panel style={{ marginTop: "6px" }}>
                <DefrostByMonthList redeems={iceData?.redeemsByMonth}></DefrostByMonthList>
              </Panel>
            </div>
            <div>
              <h2 style={{ color: "white" }}>ICE Defrosts by Day</h2>
              <Panel style={{ marginTop: "6px" }}>
                <DefrostByDayList redeems={iceData?.redeemsByDay}></DefrostByDayList>
              </Panel>
            </div>
          </GridRow>
        </div>
      </ContentWrapper>
    </PageWrapper>
  );
}

export default withRouter(IcePage);
