import React, { useState, useEffect, useMemo } from "react";

import { withRouter } from "react-router-dom";
import { Table, Button } from "@mantine/core";
import { useVersion } from "../../contexts/Application";
import Link from "../Link";

function ConvertList(converts) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const { explorer } = useVersion();

  //console.log("converts", converts);

  if (!converts.converts) {
    return <div style={{ color: "white" }}>Ice data not available</div>;
  }

  const totalItems = converts.converts.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Calculate the starting and ending index for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Sort feesByDay by element.timestamp in descending order
  const sortedRedeems = converts.converts
    .slice()
    .sort((a, b) => b.timestamp - a.timestamp);

  // Extract the items for the current page using slice on the sorted array
  const currentItems = sortedRedeems.slice(startIndex, endIndex);

  const rows = currentItems.map((element) => (
    <tr key={element.timestamp}>
      <td> 
        <Link external style={{ color: "#77B2FC" }} href={`${explorer}/tx/` + element.transaction}>{element.date}</Link>
      </td>
      <td> 
        <Link external style={{ color: "#77B2FC" }} href={`${explorer}/address/` + element.userAddress}>{element.userAddressShort}</Link>
      </td>
      <td style={{ color: "white" }}>
        {Number(Number(element.amount).toFixed(2)).toLocaleString()}
      </td>
    </tr>
  ));

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th style={{ color: "white" }}>Date</th>
            <th style={{ color: "white" }}>User</th>
            <th style={{ color: "white" }}>Amount (ICE)</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      {totalItems && totalItems > itemsPerPage ? (
        <>
          <div style={{ marginTop: "10px" }}>
            <Button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              style={{
                marginRight: "10px",
                color: "white",
                backgroundColor: "#228be6",
              }}
            >
              Previous Page
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{ color: "white", backgroundColor: "#228be6" }}
            >
              Next Page
            </Button>
          </div>
          <div style={{ color: "white", marginTop: "20px" }}>
            Showing {startIndex + 1} to {endIndex} of {totalItems} entries
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default withRouter(ConvertList);
