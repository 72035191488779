import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTimeframe, useVersion } from "./Application";
import {
  getPercentChange,
  getBlockFromTimestamp,
  getBlocksFromTimestamps,
  get2DayPercentChange,
  getTimeframe,
} from "../utils";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useAllPairData } from "./PairData";
import { BigNumber, ethers } from "ethers";
import { useTokenChartDataCombined } from "./TokenData";
import {
  ALL_POOLS_V3,
  ALL_TOKENS_V3,
  ETH_PRICE_V3,
  FTM_PRICE_V3,
  GLOBAL_CHART_V3,
  GLOBAL_DATA_V3,
  GLOBAL_TXNS_V3,
  ICE_STATS,
} from "../apollo/queries-v3";
import { MAXIMUM_TVL } from '../constants'

const UPDATE = "UPDATE";
const UPDATE_TXNS = "UPDATE_TXNS";
const UPDATE_ICE = "UPDATE_ICE";
const UPDATE_CHART = "UPDATE_CHART";
const UPDATE_ETH_PRICE = "UPDATE_ETH_PRICE";
const ETH_PRICE_KEY = "ETH_PRICE_KEY";
const UPDATE_ALL_PAIRS_IN_UNISWAP =
  "UPDAUPDATE_ALL_PAIRS_IN_UNISWAPTE_TOP_PAIRS";
const UPDATE_ALL_TOKENS_IN_UNISWAP = "UPDATE_ALL_TOKENS_IN_UNISWAP";
const UPDATE_TOP_LPS = "UPDATE_TOP_LPS";

const offsetVolumes = [];

// format dayjs with the libraries that we need
dayjs.extend(utc);
dayjs.extend(weekOfYear);

const GlobalDataContext = createContext();

function useGlobalDataContext() {
  return useContext(GlobalDataContext);
}

function reducer(state, { type, payload }) {
  switch (type) {
    case UPDATE: {
      const { data } = payload;
      return {
        ...state,
        globalData: data,
      };
    }
    case UPDATE_TXNS: {
      const { transactions } = payload;
      return {
        ...state,
        transactions,
      };
    }
    case UPDATE_ICE: {
      const { iceStats } = payload;
      return {
        ...state,
        iceStats,
      };
    }
    case UPDATE_CHART: {
      const { daily, weekly } = payload;
      return {
        ...state,
        chartData: {
          daily,
          weekly,
        },
      };
    }
    case UPDATE_ETH_PRICE: {
      const { ethPrice, oneDayPrice, ethPriceChange } = payload;
      return {
        [ETH_PRICE_KEY]: ethPrice,
        oneDayPrice,
        ethPriceChange,
      };
    }

    case UPDATE_ALL_PAIRS_IN_UNISWAP: {
      const { allPairs } = payload;
      return {
        ...state,
        allPairs,
      };
    }

    case UPDATE_ALL_TOKENS_IN_UNISWAP: {
      const { allTokens } = payload;
      return {
        ...state,
        allTokens,
      };
    }

    case UPDATE_TOP_LPS: {
      const { topLps } = payload;
      return {
        ...state,
        topLps,
      };
    }
    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`);
    }
  }
}

export default function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, {});
  const update = useCallback((data) => {
    dispatch({
      type: UPDATE,
      payload: {
        data,
      },
    });
  }, []);

  const updateTransactions = useCallback((transactions) => {
    dispatch({
      type: UPDATE_TXNS,
      payload: {
        transactions,
      },
    });
  }, []);

  const updateIce = useCallback((iceStats) => {
    dispatch({
      type: UPDATE_ICE,
      payload: {
        iceStats,
      },
    });
  }, []);

  const updateChart = useCallback((daily, weekly) => {
    dispatch({
      type: UPDATE_CHART,
      payload: {
        daily,
        weekly,
      },
    });
  }, []);

  const updateEthPrice = useCallback(
    (ethPrice, oneDayPrice, ethPriceChange) => {
      dispatch({
        type: UPDATE_ETH_PRICE,
        payload: {
          ethPrice,
          oneDayPrice,
          ethPriceChange,
        },
      });
    },
    []
  );

  const updateAllPairsInUniswap = useCallback((allPairs) => {
    dispatch({
      type: UPDATE_ALL_PAIRS_IN_UNISWAP,
      payload: {
        allPairs,
      },
    });
  }, []);

  const updateAllTokensInUniswap = useCallback((allTokens) => {
    dispatch({
      type: UPDATE_ALL_TOKENS_IN_UNISWAP,
      payload: {
        allTokens,
      },
    });
  }, []);

  const updateTopLps = useCallback((topLps) => {
    dispatch({
      type: UPDATE_TOP_LPS,
      payload: {
        topLps,
      },
    });
  }, []);
  return (
    <GlobalDataContext.Provider
      value={useMemo(
        () => [
          state,
          {
            update,
            updateTransactions,
            updateIce,
            updateChart,
            updateEthPrice,
            updateTopLps,
            updateAllPairsInUniswap,
            updateAllTokensInUniswap,
          },
        ],
        [
          state,
          update,
          updateTransactions,
          updateIce,
          updateTopLps,
          updateChart,
          updateEthPrice,
          updateAllPairsInUniswap,
          updateAllTokensInUniswap,
        ]
      )}
    >
      {children}
    </GlobalDataContext.Provider>
  );
}

/**
 * Gets all the global data for the overview page.
 * Needs current eth price and the old eth price to get
 * 24 hour USD changes.
 * @param {*} ethPrice
 * @param {*} oldEthPrice
 */

async function getGlobalData(ethPrice, oldEthPrice, client, blockClient) {
  // data for each day , historic data used for % changes
  let data = {};
  let oneDayData = {};
  let twoDayData = {};

  try {
    // get timestamps for the days
    const utcCurrentTime = dayjs();
    const utcOneDayBack = utcCurrentTime.subtract(1, "day").unix();
    const utcTwoDaysBack = utcCurrentTime.subtract(2, "day").unix();
    const utcOneWeekBack = utcCurrentTime.subtract(1, "week").unix();
    const utcTwoWeeksBack = utcCurrentTime.subtract(2, "week").unix();
    // get the blocks needed for time travel queries
    let [oneDayBlock, twoDayBlock, oneWeekBlock, twoWeekBlock] =
      await getBlocksFromTimestamps(blockClient, [
        utcOneDayBack,
        utcTwoDaysBack,
        utcOneWeekBack,
        utcTwoWeeksBack,
      ]);

    // fetch the global data
    let result = await client.query({
      query: GLOBAL_DATA_V3(),
      fetchPolicy: "cache-first",
    });

    data = result.data.uniswapFactories[0];

    // fetch the historical data
    let oneDayResult = await client.query({
      query: GLOBAL_DATA_V3(oneDayBlock?.number),
      fetchPolicy: "cache-first",
    });
    oneDayData = oneDayResult.data.uniswapFactories[0];

    let twoDayResult = await client.query({
      query: GLOBAL_DATA_V3(twoDayBlock?.number),
      fetchPolicy: "cache-first",
    });
    twoDayData = twoDayResult.data.uniswapFactories[0];

    let oneWeekResult = await client.query({
      query: GLOBAL_DATA_V3(oneWeekBlock?.number),
      fetchPolicy: "cache-first",
    });
    const oneWeekData = oneWeekResult.data.uniswapFactories[0];

    let twoWeekResult = await client.query({
      query: GLOBAL_DATA_V3(twoWeekBlock?.number),
      fetchPolicy: "cache-first",
    });
    const twoWeekData = twoWeekResult.data.uniswapFactories[0];

    if (data && oneDayData && twoDayData) {
      let [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
        data.totalVolumeUSD,
        oneDayData.totalVolumeUSD,
        twoDayData.totalVolumeUSD
      );

      let [oneDayFeeUSD, feeChangeUSD] = get2DayPercentChange(
        data.totalFeeUSD,
        oneDayData.totalFeeUSD,
        twoDayData.totalFeeUSD
      );

      let oneWeekVolume = 0;
      let weeklyVolumeChange = 0;
      if (twoWeekData) {
        [oneWeekVolume, weeklyVolumeChange] = get2DayPercentChange(
          data.totalVolumeUSD,
          oneWeekData.totalVolumeUSD,
          twoWeekData.totalVolumeUSD
        );
      }

      const [oneDayTxns, txnChange] = get2DayPercentChange(
        data.txCount,
        oneDayData.txCount ? oneDayData.txCount : 0,
        twoDayData.txCount ? twoDayData.txCount : 0
      );

      // format the total liquidity in USD
      data.totalLiquidityUSD = data.totalLiquidityETH * ethPrice;
      const liquidityChangeUSD = getPercentChange(
        data.totalLiquidityETH * ethPrice,
        oneDayData.totalLiquidityETH * oldEthPrice
      );

      // add relevant fields with the calculated amounts
      data.oneDayVolumeUSD = oneDayVolumeUSD;
      data.oneDayFeeUSD = oneDayFeeUSD;
      data.oneDayFeeChange = feeChangeUSD;
      data.oneWeekVolume = oneWeekVolume;
      data.weeklyVolumeChange = weeklyVolumeChange;
      data.volumeChangeUSD = volumeChangeUSD;
      data.liquidityChangeUSD = liquidityChangeUSD;
      data.oneDayTxns = oneDayTxns;
      data.txnChange = txnChange;
    }
  } catch (e) {
    console.log(e);
  }

  return data;
}

/**
 * Get historical data for volume and liquidity used in global charts
 * on main page
 * @param {*} oldestDateToFetch // start of window to fetch from
 */

let checked = false;

const getChartData = async (oldestDateToFetch, offsetData, client) => {
  let data = [];
  let weeklyData = [];
  const utcEndTime = dayjs.utc();
  let skip = 0;
  let allFound = false;

  try {
    while (!allFound) {
      let result = await client.query({
        query: GLOBAL_CHART_V3,
        variables: {
          startTime: oldestDateToFetch,
          skip
        },
        fetchPolicy: "cache-first",
      });
      skip += 1000;
      data = data.concat(result.data.uniswapDayDatas);
      if (result.data.uniswapDayDatas.length < 1000) {
        allFound = true;
      }
    }

    if (data) {
      let dayIndexSet = new Set();
      let dayIndexArray = [];
      const oneDay = 24 * 60 * 60;

      // for each day, parse the daily volume and format for chart array
      data.forEach((dayData, i) => {
        // add the day index to the set of days
        dayIndexSet.add((data[i].date / oneDay).toFixed(0));
        dayIndexArray.push(data[i]);
        dayData.dailyVolumeUSD = parseFloat(dayData.dailyVolumeUSD);
        dayData.dailyFeeUSD = parseFloat(dayData.dailyFeeUSD);
        // Set the total liquidity to the previous day if exceeding MAXIMUM_TVL
        dayData.totalLiquidityUSD = dayData.totalLiquidityUSD > MAXIMUM_TVL ? data[i - 1].totalLiquidityUSD : dayData.totalLiquidityUSD;
      });

      // fill in empty days ( there will be no day datas if no trades made that day )
      let timestamp = data[0]?.date ? data[0].date : oldestDateToFetch;
      let latestLiquidityUSD = data[0]?.totalLiquidityUSD;
      let latestDayDats = data[0]?.mostLiquidTokens;
      let index = 1;
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay;
        let currentDayIndex = (nextDay / oneDay).toFixed(0);

        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            date: nextDay,
            dailyVolumeUSD: 0,
            dailyFeeUSD: 0,
            totalLiquidityUSD: latestLiquidityUSD,
            mostLiquidTokens: latestDayDats,
          });
        } else {
          latestLiquidityUSD = dayIndexArray[index].totalLiquidityUSD;
          latestDayDats = dayIndexArray[index].mostLiquidTokens;
          index = index + 1;
        }
        timestamp = nextDay;
      }
    }

    // format weekly data for weekly sized chunks
    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1));
    let startIndexWeekly = -1;
    let currentWeek = -1;

    data.forEach((entry, i) => {
      const date = data[i].date;

      // hardcoded fix for offset volume
      offsetData &&
        !checked &&
        offsetData.map((dayData) => {
          if (dayData[date]) {
            data[i].dailyVolumeUSD =
              parseFloat(data[i].dailyVolumeUSD) -
              parseFloat(dayData[date].dailyVolumeUSD);
          }
          return true;
        });

      const week = dayjs.utc(dayjs.unix(data[i].date)).week();
      if (week !== currentWeek) {
        currentWeek = week;
        startIndexWeekly++;
      }
      weeklyData[startIndexWeekly] = weeklyData[startIndexWeekly] || {};
      weeklyData[startIndexWeekly].date = data[i].date;
      weeklyData[startIndexWeekly].weeklyVolumeUSD =
        (weeklyData[startIndexWeekly].weeklyVolumeUSD ?? 0) +
        data[i].dailyVolumeUSD;
    });

    if (!checked) {
      checked = true;
    }
  } catch (e) {
    console.log(e);
  }
  return [data, weeklyData];
};

function groupByDay(redeems) {
  const grouped = {};

  redeems.forEach(redeem => {
    const date = new Date(redeem.timestampOut * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-11
    const day = date.getDate();
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    const sortKey = parseInt(`${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`); // Format as 'YYYYMMDD'

    if (!grouped[formattedDate]) {
      grouped[formattedDate] = {
        totalAmount: 0,
        totalAmountBurn: 0,
        sortKey: sortKey,
        displayDate: formattedDate
      };
    }

    grouped[formattedDate].totalAmount += parseFloat(redeem.amountOut);
    grouped[formattedDate].totalAmountBurn += parseFloat(redeem.amountBurn);
  });

  return grouped;
}

function groupByMonth(redeems) {
  const grouped = {};

  redeems.forEach(redeem => {
    const date = new Date(redeem.timestampOut * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns 0-11
    const formattedMonth = `${year}-${month.toString().padStart(2, '0')}`; // Format as 'YYYY-MM'
    const displayMonth = date.toLocaleDateString("en-US", { year: "2-digit", month: "short" });

    if (!grouped[formattedMonth]) {
      grouped[formattedMonth] = {
        totalAmount: 0,
        totalAmountBurn: 0,
        displayMonth: displayMonth, // for displaying the month in a user-friendly format
        sortKey: parseInt(formattedMonth.replace('-', '')) // as a number for sorting
      };
    }

    grouped[formattedMonth].totalAmount += parseFloat(redeem.amountOut);
    grouped[formattedMonth].totalAmountBurn += parseFloat(redeem.amountBurn);
  });

  return grouped;
}

/**
 * Get ICE statistics
 */
const getIceStatistics = async (client) => {
  let iceStats = {};

  try {
    let result = await client.query({
      query: ICE_STATS,
      fetchPolicy: "cache-first",
    });
    iceStats.redeems = [];
    iceStats.redeemsByDay = [];
    iceStats.redeemsByMonth = [];
    iceStats.converts = [];
    iceStats.cancelRedeems = [];
    iceStats.finalizeRedeems = [];

    if (result?.data?.redeems) {
      iceStats.redeems = result.data.redeems.map((redeem) => {
        const date = new Date(redeem.timestamp * 1000);
        const dateOut = new Date(redeem.matureTimestamp * 1000);
        return {
          timestamp: redeem.timestamp,
          timestampOut: redeem.matureTimestamp,
          amount: ethers.utils.formatEther(redeem.amount),
          amountOut: ethers.utils.formatEther(redeem.amountOut),
          amountBurn: ethers.utils.formatEther(
            BigNumber.from(redeem.amount).sub(BigNumber.from(redeem.amountOut))
          ),
          date: date.toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
          dateOut: dateOut.toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric"
          }),
          transactionHash: redeem.transactionHash,
          userAddress: redeem.userAddress,
          duration: redeem.duration / 60 / 60 / 24,
          transaction: redeem.transactionHash,
          userAddress: redeem.userAddress,
          userAddressShort: redeem.userAddress.substring(0, 3) + "**" + redeem.userAddress.substring(redeem.userAddress.length - 3)
        };
      });
      iceStats.redeemsByDay = groupByDay(iceStats.redeems);
      iceStats.redeemsByMonth = groupByMonth(iceStats.redeems);
    }
    if (result?.data?.finalizeRedeems) {
      iceStats.finalizeRedeems = result.data.finalizeRedeems.map((redeem) => {
        const date = new Date(redeem.timestamp * 1000);
        return {
          timestamp: redeem.timestamp,
          amount: ethers.utils.formatEther(redeem.amount),
          amountOut: ethers.utils.formatEther(redeem.slushAmount),
          amountBurn: ethers.utils.formatEther(
            BigNumber.from(redeem.amount).sub(BigNumber.from(redeem.slushAmount))
          ),
          date: date.toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
          transactionHash: redeem.transactionHash,
          userAddress: redeem.userAddress,
          transaction: redeem.transactionHash,
          userAddress: redeem.userAddress,
          userAddressShort: redeem.userAddress.substring(0, 3) + "**" + redeem.userAddress.substring(redeem.userAddress.length - 3)
        };
      });
    }
    if (result?.data?.converts) {
      iceStats.converts = result.data.redeems.map((convert) => {
        const date = new Date(convert.timestamp * 1000);
        return {
          timestamp: convert.timestamp,
          amount: ethers.utils.formatEther(convert.amount),
          date: date.toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
          transaction: convert.transactionHash,
          userAddress: convert.userAddress,
          userAddressShort: convert.userAddress.substring(0, 3) + "**" + convert.userAddress.substring(convert.userAddress.length - 3)
        };
      });
    }
    if (result?.data?.cancelRedeems) {
      iceStats.cancelRedeems = result.data.cancelRedeems.map((cancelRedeem) => {
        const date = new Date(cancelRedeem.timestamp * 1000);
        return {
          timestamp: cancelRedeem.timestamp,
          amount: ethers.utils.formatEther(cancelRedeem.amount),
          date: date.toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
          transaction: cancelRedeem.transactionHash,
          userAddress: cancelRedeem.userAddress,
          userAddressShort: cancelRedeem.userAddress.substring(0, 3) + "**" + cancelRedeem.userAddress.substring(cancelRedeem.userAddress.length - 3)
        };
      });
    }
  } catch (e) {
    console.log(e);
  }

  return iceStats;
};

/**
 * Get and format transactions for global page
 */
const getGlobalTransactions = async (client) => {
  let transactions = {};

  try {
    let result = await client.query({
      query: GLOBAL_TXNS_V3,
      fetchPolicy: "cache-first",
    });
    transactions.mints = [];
    transactions.burns = [];
    transactions.swaps = [];
    result?.data?.transactions &&
      result.data.transactions.map((transaction) => {
        if (transaction.mints.length > 0) {
          transaction.mints.map((mint) => {
            return transactions.mints.push(mint);
          });
        }
        if (transaction.burns.length > 0) {
          transaction.burns.map((burn) => {
            return transactions.burns.push(burn);
          });
        }
        if (transaction.swaps.length > 0) {
          transaction.swaps.map((swap) => {
            return transactions.swaps.push(swap);
          });
        }
        return true;
      });
  } catch (e) {
    console.log(e);
  }

  return transactions;
};

/**
 * Gets the current price  of ETH, 24 hour price, and % change between them
 */
const getEthPrice = async (client, blockClient) => {
  const utcCurrentTime = dayjs();
  const utcOneDayBack = utcCurrentTime
    .subtract(1, "day")
    .startOf("minute")
    .unix();

  let ethPrice = 0;
  let ethPriceOneDay = 0;
  let priceChangeETH = 0;

  try {
    let oneDayBlock = await getBlockFromTimestamp(blockClient, utcOneDayBack);
    let result = await client.query({
      query: ETH_PRICE_V3(),
      fetchPolicy: "cache-first",
    });
    let resultOneDay = await client.query({
      query: ETH_PRICE_V3(oneDayBlock),
      fetchPolicy: "cache-first",
    });
    const currentPrice = result?.data?.bundles[0]?.ethPrice;
    const oneDayBackPrice = resultOneDay?.data?.bundles[0]?.ethPrice;
    priceChangeETH = getPercentChange(currentPrice, oneDayBackPrice);
    ethPrice = currentPrice || "0";
    ethPriceOneDay = oneDayBackPrice || "0";
  } catch (e) {
    console.log(e);
  }

  return [ethPrice, ethPriceOneDay, priceChangeETH];
};

const PAIRS_TO_FETCH = 500;
const TOKENS_TO_FETCH = 500;

/**
 * Loop through every pair on uniswap, used for search
 */
async function getAllPairsOnUniswap(client) {
  try {
    let allFound = false;
    let pairs = [];
    let skipCount = 0;
    while (!allFound) {
      let result = await client.query({
        query: ALL_POOLS_V3,
        variables: {
          skip: skipCount,
        },
        fetchPolicy: "cache-first",
      });
      skipCount = skipCount + PAIRS_TO_FETCH;
      pairs = pairs.concat(result?.data?.pairs);
      if (
        result?.data?.pairs.length < PAIRS_TO_FETCH ||
        pairs.length > PAIRS_TO_FETCH
      ) {
        allFound = true;
      }
    }
    return pairs;
  } catch (e) {
    console.log(e);
  }
}

/**
 * Loop through every token on uniswap, used for search
 */
async function getAllTokensOnUniswap(client) {
  try {
    let allFound = false;
    let skipCount = 0;
    let tokens = [];
    while (!allFound) {
      let result = await client.query({
        query: ALL_TOKENS_V3,
        variables: {
          skip: skipCount,
        },
        fetchPolicy: "cache-first",
      });
      tokens = tokens.concat(result?.data?.tokens);
      if (
        result?.data?.tokens?.length < TOKENS_TO_FETCH ||
        tokens.length > TOKENS_TO_FETCH
      ) {
        allFound = true;
      }
      skipCount = skipCount += TOKENS_TO_FETCH;
    }
    return tokens;
  } catch (e) {
    console.log(e);
  }
}

/**
 * Hook that fetches overview data, plus all tokens and pairs for search
 */
export function useGlobalData() {
  const [state, { update, updateAllPairsInUniswap, updateAllTokensInUniswap }] =
    useGlobalDataContext();
  const [ethPrice, oldEthPrice] = useEthPrice();
  const data = state?.globalData;

  const { client, blockClient } = useVersion();

  useEffect(() => {
    async function fetchData() {
      let globalData = await getGlobalData(
        ethPrice,
        oldEthPrice,
        client,
        blockClient
      );

      globalData && update(globalData);

      let allPairs = await getAllPairsOnUniswap(client);
      updateAllPairsInUniswap(allPairs);

      let allTokens = await getAllTokensOnUniswap(client);
      updateAllTokensInUniswap(allTokens);
    }
    if (!data && ethPrice && oldEthPrice && client && blockClient) {
      fetchData();
    }
  }, [
    ethPrice,
    oldEthPrice,
    update,
    data,
    updateAllPairsInUniswap,
    updateAllTokensInUniswap,
    client,
    blockClient,
  ]);
  return data || {};
}

export function useGlobalChartData() {
  const [state, { updateChart }] = useGlobalDataContext();
  const [oldestDateFetch, setOldestDateFetched] = useState();
  const [activeWindow] = useTimeframe();

  const chartDataDaily = state?.chartData?.daily;
  const chartDataWeekly = state?.chartData?.weekly;

  const { client } = useVersion();

  /**
   * Keep track of oldest date fetched. Used to
   * limit data fetched until its actually needed.
   * (dont fetch year long stuff unless year option selected)
   */
  useEffect(() => {
    // based on window, get starttime
    let startTime = getTimeframe(activeWindow);

    if ((activeWindow && startTime < oldestDateFetch) || !oldestDateFetch) {
      setOldestDateFetched(startTime);
    }
  }, [activeWindow, oldestDateFetch]);

  // fix for rebass tokens

  const combinedData = useTokenChartDataCombined(offsetVolumes);
  /**
   * Fetch data if none fetched or older data is needed
   */
  useEffect(() => {
    async function fetchData() {
      // historical stuff for chart
      let [newChartData, newWeeklyData] = await getChartData(
        oldestDateFetch,
        combinedData,
        client
      );

      updateChart(newChartData, newWeeklyData);
    }
    if (
      oldestDateFetch &&
      !(chartDataDaily && chartDataWeekly) &&
      combinedData &&
      client
    ) {
      fetchData();
    }
  }, [
    chartDataDaily,
    chartDataWeekly,
    combinedData,
    oldestDateFetch,
    updateChart,
    client,
  ]);

  return [chartDataDaily, chartDataWeekly];
}

export function useIce() {
  const [state, { updateIce }] = useGlobalDataContext();
  const iceStats = state?.iceStats;

  const { iceClient } = useVersion();

  useEffect(() => {
    async function fetchData() {
      if (!iceStats) {
        let ice = await getIceStatistics(iceClient);
        updateIce(ice);
      }
    }
    iceClient && fetchData();
  }, [updateIce, iceStats, iceClient]);
  return iceStats;
}

export function useGlobalTransactions() {
  const [state, { updateTransactions }] = useGlobalDataContext();
  const transactions = state?.transactions;

  const { client } = useVersion();

  useEffect(() => {
    async function fetchData() {
      if (!transactions) {
        let txns = await getGlobalTransactions(client);
        updateTransactions(txns);
      }
    }
    client && fetchData();
  }, [updateTransactions, transactions, client]);
  return transactions;
}

export function useEthPrice() {
  const [state, { updateEthPrice }] = useGlobalDataContext();
  const ethPrice = state?.[ETH_PRICE_KEY];
  const ethPriceOld = state?.["oneDayPrice"];

  const { client, blockClient } = useVersion();

  useEffect(() => {
    async function checkForEthPrice() {
      if (!ethPrice) {
        let [newPrice, oneDayPrice, priceChange] = await getEthPrice(
          client,
          blockClient
        );
        updateEthPrice(newPrice, oneDayPrice, priceChange);
      }
    }
    client && blockClient && checkForEthPrice();
  }, [ethPrice, updateEthPrice, client, blockClient]);

  return [ethPrice, ethPriceOld];
}

export function useAllPairsInUniswap() {
  const [state] = useGlobalDataContext();
  let allPairs = state?.allPairs;

  return allPairs || [];
}

export function useAllTokensInUniswap() {
  const [state] = useGlobalDataContext();
  let allTokens = state?.allTokens;

  return allTokens || [];
}
