import React, { useState, useMemo, useEffect, useRef } from "react";
import { ResponsiveContainer } from "recharts";
import { timeframeOptions, FEE_SPLIT_TELOS, FEE_SPLIT_MANTLE } from "../../constants";
import { useGlobalChartData, useGlobalData } from "../../contexts/GlobalData";
import { useMedia } from "react-use";
import DropdownSelect from "../DropdownSelect";
import TradingViewChart, { CHART_TYPES } from "../TradingviewChart";
import { RowFixed } from "../Row";
import { OptionButton } from "../ButtonStyled";
import { getTimeframe } from "../../utils";
import { TYPE } from "../../Theme";
import { useVersion } from "../../contexts/Application";

const CHART_VIEW = {
  VOLUME: "Volume",
  LIQUIDITY: "Liquidity",
  FEES: "Fees",
  FEES_ICV: "Fees Ice Cream Van",
  FEES_Buyback: "Fees Buyback",
  FEES_ZV: "Fees Zombie Van",
  FEES_SWAPSICLE: "Fees Swapsicle",
};

const VOLUME_WINDOW = {
  WEEKLY: "WEEKLY",
  DAYS: "DAYS",
};

const GlobalChart = ({ display }) => {
  // chart options
  const [chartView, setChartView] = useState(
    display === "volume"
      ? CHART_VIEW.VOLUME
      : display === "liquidity"
      ? CHART_VIEW.LIQUIDITY
      : display === "fees"
      ? CHART_VIEW.FEES
      : display === "fees_icv"
      ? CHART_VIEW.FEES_ICV
      : display === "fees_zv"
      ? CHART_VIEW.FEES_ZV
      : display === "fees_buyback"
      ? CHART_VIEW.FEES_Buyback
      : display === "fees_swapsicle"
      ? CHART_VIEW.FEES_SWAPSICLE
      : display === "fees"
  );

  // time window and window size for chart
  const timeWindow = timeframeOptions.ALL_TIME;
  const [volumeWindow, setVolumeWindow] = useState(VOLUME_WINDOW.DAYS);

  // global historical data
  const [dailyData, weeklyData] = useGlobalChartData();
  const [feesData, setFeesData] = useState();

  const { versionLabel } = useVersion();
  const FEE_SPLIT = versionLabel === "telos" ? FEE_SPLIT_TELOS : FEE_SPLIT_MANTLE;

  useEffect(() => {
    const feeData = dailyData.map((item) => {
      return {
        date: item.date,
        feesUSD: item.feesUSD,
        feesUSDBuyback: item.feesUSD * FEE_SPLIT.BUYBACK,
        feesUSDSwapsicle: item.feesUSD * FEE_SPLIT.PLATFORM,
        feesUSDICV: item.feesUSD * FEE_SPLIT.ICECREAMVAN,
        feesUSDZV: item.feesUSD * FEE_SPLIT.ZOMBIEVAN,
      };
    });
    setFeesData(feeData);
  }, [dailyData]);

  const {
    totalLiquidityUSD,
    oneDayVolumeUSD,
    volumeChangeUSD,
    liquidityChangeUSD,
    oneWeekVolume,
    weeklyVolumeChange,
    feesUSD,
    oneDayFeeChange,
    oneDayFeeUSD,
  } = useGlobalData();

  // based on window, get starttim
  let utcStartTime = getTimeframe(timeWindow);

  const chartDataFiltered = useMemo(() => {
    let currentData =
      volumeWindow === VOLUME_WINDOW.DAYS ? dailyData : weeklyData;
    return (
      currentData &&
      Object.keys(currentData)
        ?.map((key) => {
          let item = currentData[key];
          if (item.date > utcStartTime) {
            return item;
          } else {
            return;
          }
        })
        .filter((item) => {
          return !!item;
        })
    );
  }, [dailyData, utcStartTime, volumeWindow, weeklyData]);
  const below800 = useMedia("(max-width: 800px)");

  // update the width on a window resize
  const ref = useRef();
  const isClient = typeof window === "object";
  const [width, setWidth] = useState(ref?.current?.container?.clientWidth);
  useEffect(() => {
    if (!isClient) {
      return false;
    }
    function handleResize() {
      setWidth(ref?.current?.container?.clientWidth ?? width);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isClient, width]); // Empty array ensures that effect is only run on mount and unmount

  return chartDataFiltered ? (
    <>
      {below800 && (
        <DropdownSelect
          options={CHART_VIEW}
          active={chartView}
          setActive={setChartView}
          color={"#ff007a"}
        />
      )}

      {chartDataFiltered && chartView === CHART_VIEW.LIQUIDITY && (
        <ResponsiveContainer aspect={60 / 28} ref={ref}>
          <TradingViewChart
            data={dailyData}
            base={totalLiquidityUSD}
            baseChange={liquidityChangeUSD}
            title="Liquidity"
            field="totalLiquidityUSD"
            width={width}
            type={CHART_TYPES.AREA}
          />
        </ResponsiveContainer>
      )}
      {chartDataFiltered && chartView === CHART_VIEW.FEES && (
        <ResponsiveContainer aspect={60 / 28} ref={ref}>
          <TradingViewChart
            data={dailyData}
            base={oneDayFeeUSD}
            baseChange={oneDayFeeChange}
            title="Total Fees (24h)"
            field="feesUSD"
            width={width}
            type={CHART_TYPES.AREA}
          />
        </ResponsiveContainer>
      )}
      {chartDataFiltered && chartView === CHART_VIEW.FEES_Buyback && (
        <ResponsiveContainer aspect={60 / 28} ref={ref}>
          <TradingViewChart
            data={feesData}
            base={oneDayFeeUSD * FEE_SPLIT.BUYBACK}
            baseChange={oneDayFeeChange}
            title="Buyback Fees (24h)"
            field="feesUSDBuyback"
            width={width}
            type={CHART_TYPES.AREA}
          />
        </ResponsiveContainer>
      )}
      {chartDataFiltered && chartView === CHART_VIEW.FEES_ICV && (
        <ResponsiveContainer aspect={60 / 28} ref={ref}>
          <TradingViewChart
            data={feesData}
            base={oneDayFeeUSD * FEE_SPLIT.ICECREAMVAN}
            baseChange={oneDayFeeChange}
            title="ICV Fees (24h)"
            field="feesUSDICV"
            width={width}
            type={CHART_TYPES.AREA}
          />
        </ResponsiveContainer>
      )}
      {chartDataFiltered && chartView === CHART_VIEW.FEES_ZV && (
        <ResponsiveContainer aspect={60 / 28} ref={ref}>
          <TradingViewChart
            data={feesData}
            base={oneDayFeeUSD * FEE_SPLIT.ZOMBIEVAN}
            baseChange={oneDayFeeChange}
            title="ZV Fees (24h)"
            field="feesUSDZV"
            width={width}
            type={CHART_TYPES.AREA}
          />
        </ResponsiveContainer>
      )}
      {chartDataFiltered && chartView === CHART_VIEW.FEES_SWAPSICLE && (
        <ResponsiveContainer aspect={60 / 28} ref={ref}>
          <TradingViewChart
            data={feesData}
            base={oneDayFeeUSD * FEE_SPLIT.PLATFORM}
            baseChange={oneDayFeeChange}
            title="Platform Fees (24h)"
            field="feesUSDSwapsicle"
            width={width}
            type={CHART_TYPES.AREA}
          />
        </ResponsiveContainer>
      )}
      {chartDataFiltered && chartView === CHART_VIEW.VOLUME && (
        <ResponsiveContainer aspect={60 / 28}>
          <TradingViewChart
            data={chartDataFiltered}
            base={
              volumeWindow === VOLUME_WINDOW.WEEKLY
                ? oneWeekVolume
                : oneDayVolumeUSD
            }
            baseChange={
              volumeWindow === VOLUME_WINDOW.WEEKLY
                ? weeklyVolumeChange
                : volumeChangeUSD
            }
            title={
              volumeWindow === VOLUME_WINDOW.WEEKLY ? "Volume (7d)" : "Volume"
            }
            field={
              volumeWindow === VOLUME_WINDOW.WEEKLY
                ? "weeklyVolumeUSD"
                : "dailyVolumeUSD"
            }
            width={width}
            type={CHART_TYPES.BAR}
            useWeekly={volumeWindow === VOLUME_WINDOW.WEEKLY}
          />
        </ResponsiveContainer>
      )}
      {display === "volume" && (
        <RowFixed
          style={{
            bottom: "70px",
            position: "absolute",
            left: "20px",
            zIndex: 10,
          }}
        >
          <OptionButton
            active={volumeWindow === VOLUME_WINDOW.DAYS}
            onClick={() => setVolumeWindow(VOLUME_WINDOW.DAYS)}
          >
            <TYPE.body>D</TYPE.body>
          </OptionButton>
          <OptionButton
            style={{ marginLeft: "4px" }}
            active={volumeWindow === VOLUME_WINDOW.WEEKLY}
            onClick={() => setVolumeWindow(VOLUME_WINDOW.WEEKLY)}
          >
            <TYPE.body>W</TYPE.body>
          </OptionButton>
        </RowFixed>
      )}
    </>
  ) : (
    ""
  );
};

export default GlobalChart;
