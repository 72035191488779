import React, { useState, useEffect, useMemo } from "react";

import { withRouter } from "react-router-dom";
import { Table, Button } from "@mantine/core";

function FeesList(fees) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  if (!fees?.feesByDay) {
    return <div>Fees data not available</div>;
  }

  const totalItems = fees.feesByDay.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Calculate the starting and ending index for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Sort feesByDay by element.timestamp in descending order
  const sortedFees = fees.feesByDay
    .slice()
    .sort((a, b) => b.timestamp - a.timestamp);

  // Extract the items for the current page using slice on the sorted array
  const currentItems = sortedFees.slice(startIndex, endIndex);

  const rows = currentItems.map((element) => (
    <tr key={element.timestamp}>
      <td style={{ color: "white" }}>
        {element.date ? element.date : element.month + " " + element.year}
      </td>
      <td style={{ color: "white" }}>
        {"$" + Number(Number(element.feesUSD).toFixed(2)).toLocaleString()}
      </td>
      <td style={{ color: "white" }}>
        {"$" +
          Number(Number(element.feesUSDBuyback).toFixed(2)).toLocaleString()}
      </td>
      <td style={{ color: "white" }}>
        {"$" +
          Number(Number(element.feesUSDSwapsicle).toFixed(2)).toLocaleString()}
      </td>
      <td style={{ color: "white" }}>
        {"$" + Number(Number(element.feesUSDICV).toFixed(2)).toLocaleString()}
      </td>
      <td style={{ color: "white" }}>
        {"$" + Number(Number(element.feesUSDZV).toFixed(2)).toLocaleString()}
      </td>
    </tr>
  ));

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th style={{ color: "white" }}>Date</th>
            <th style={{ color: "white" }}>Total</th>
            <th style={{ color: "white" }}>Buyback</th>
            <th style={{ color: "white" }}>Platform</th>
            <th style={{ color: "white" }}>Ice Cream Van</th>
            <th style={{ color: "white" }}>Zombie Van</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      {totalItems && totalItems > itemsPerPage ? (
        <>
          <div style={{ marginTop: "10px" }}>
            <Button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              style={{ marginRight: "10px", color: "white", backgroundColor: "#228be6" }}
            >
              Previous Page
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{ color: "white", backgroundColor: "#228be6" }}
            >
              Next Page
            </Button>
          </div>
          <div style={{ color: "white", marginTop: "20px" }}>
            Showing {startIndex + 1} to {endIndex} of {totalItems} entries
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default withRouter(FeesList);
