import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { isAddress } from "../../utils/index.js";
import defaultTokenList from "@swapsicledex/swapsicle-default-token-list";
import { WETH } from "../../constants";

const BAD_IMAGES = {};

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`;

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;

  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`;

const StyledEthereumLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`;

export default function TokenLogo({
  address,
  header = false,
  size = "24px",
  ...rest
}) {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [address]);

  if (error || BAD_IMAGES[address]) {
    return (
      <Inline>
        <span
          {...rest}
          alt={""}
          style={{ fontSize: size }}
          role="img"
          aria-label="face"
        >
          ?
        </span>
      </Inline>
    );
  }

  if (
    address?.toLowerCase() === WETH.mantle ||
    address?.toLowerCase() === WETH.mantleTestnet
  ) {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={
            "https://raw.githubusercontent.com/swapsicledex/swapsicle-token-list/master/logos/mantle/MNT/logo.svg"
          }
          style={{
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.075)",
            borderRadius: "24px",
          }}
          alt=""
        />
      </StyledEthereumLogo>
    );
  }

  if (
    address?.toLowerCase() === WETH.telos ||
    address?.toLowerCase() === WETH.telosTestnet
  ) {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={
            "https://app.swapsicle.io/_next/image?url=%2Fimg%2Ftelos.svg&w=64&q=75"
          }
          style={{
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.075)",
            borderRadius: "24px",
          }}
          alt=""
        />
      </StyledEthereumLogo>
    );
  }

  const localpath = defaultTokenList.tokens.filter(
    (a) =>
      a.address === isAddress(address) || a.address === address.toLowerCase()
  );

  return (
    <Inline>
      <Image
        {...rest}
        alt={""}
        src={localpath[0] ? localpath[0].logoURI : ""}
        size={size}
        onError={(event) => {
          BAD_IMAGES[address] = true;
          setError(true);
          event.preventDefault();
        }}
      />
    </Inline>
  );
}
