import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { useVersion } from '../../contexts/Application'
import { BasicLink } from '../Link'

const VersionSwitcherToggler = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
  border: 1px solid #434564;
  border-radius: 8px;
`

const VersionSwitcherTogglerItem = styled(BasicLink)`
  text-transform: capitalize;
  color: white;
  width: 100%;
  padding: 6px;
  border-radius: 8px;
  background-color: ${({ isActive }) => (isActive ? '#5161F2' : 'transparent')};
  opacity: ${({ isActive }) => (isActive ? '1' : '0.6')};
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  :hover {
    opacity: 1;
  }
`

function VersionSwitcher() {
  const { versionLabel } = useVersion()

  const versions = ['mantle', 'telos']

  return (
    <VersionSwitcherToggler>
      {versions.map((version, i) => (
        <VersionSwitcherTogglerItem
          key={i}
          to={`/home/${version}`}
          isActive={version === versionLabel}
          onClick={() => setTimeout(() => window.location.reload(), 500)}
        >
          {version}
        </VersionSwitcherTogglerItem>
      ))}
    </VersionSwitcherToggler>
  )
}

export default withRouter(VersionSwitcher)
