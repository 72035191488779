import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { ChainId, getSubgraphUrl, SUBGRAPH_NAMES } from '../git-submodules/swapsicle-subgraph-urls'

export const getClient = (url) =>
  new ApolloClient({
    link: new HttpLink({
      uri: url,
    }),
    cache: new InMemoryCache(),
    queryDeduplication: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })

export const GRAPH_CLIENTS = {
  info: {
    mantle: getClient(getSubgraphUrl(SUBGRAPH_NAMES.ANALYTICS, ChainId.MANTLE)),
    mantleTestnet: getClient(getSubgraphUrl(SUBGRAPH_NAMES.ANALYTICS, ChainId.MANTLE_TESTNET)),
    telos: getClient(getSubgraphUrl(SUBGRAPH_NAMES.ANALYTICS, ChainId.TELOS)),
    telosTestnet: getClient(getSubgraphUrl(SUBGRAPH_NAMES.ANALYTICS, ChainId.TELOS_TESTNET)),
    taiko: getClient(getSubgraphUrl(SUBGRAPH_NAMES.ANALYTICS, ChainId.TAIKO)),
    taikoTestnet: getClient(getSubgraphUrl(SUBGRAPH_NAMES.ANALYTICS, ChainId.TAIKO_TESTNET)),
  },
  blocks: {
    mantle: getClient(getSubgraphUrl(SUBGRAPH_NAMES.BLOCKS, ChainId.MANTLE)),
    mantleTestnet: getClient(getSubgraphUrl(SUBGRAPH_NAMES.BLOCKS, ChainId.MANTLE_TESTNET)),
    telos: getClient(getSubgraphUrl(SUBGRAPH_NAMES.BLOCKS, ChainId.TELOS)),
    telosTestnet: getClient(getSubgraphUrl(SUBGRAPH_NAMES.BLOCKS, ChainId.TELOS_TESTNET)),
    taiko: getClient(getSubgraphUrl(SUBGRAPH_NAMES.BLOCKS, ChainId.TAIKO)),
    taikoTestnet: getClient(getSubgraphUrl(SUBGRAPH_NAMES.BLOCKS, ChainId.TAIKO_TESTNET)),
  },
  ice: {
    mantle: getClient(getSubgraphUrl(SUBGRAPH_NAMES.ICE_TOKEN, ChainId.MANTLE)),
    mantleTestnet: getClient(getSubgraphUrl(SUBGRAPH_NAMES.ICE_TOKEN, ChainId.MANTLE_TESTNET)),
    telos: getClient(getSubgraphUrl(SUBGRAPH_NAMES.ICE_TOKEN, ChainId.TELOS)),
    telosTestnet: getClient(getSubgraphUrl(SUBGRAPH_NAMES.ICE_TOKEN, ChainId.TELOS_TESTNET)),
    taiko: getClient(getSubgraphUrl(SUBGRAPH_NAMES.ICE_TOKEN, ChainId.TAIKO)),
    taikoTestnet: getClient(getSubgraphUrl(SUBGRAPH_NAMES.ICE_TOKEN, ChainId.TAIKO_TESTNET)),
  },
}

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})
