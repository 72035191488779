import React, { useState } from "react";
import styled from "styled-components";
import { ApolloProvider } from "react-apollo";
import { Route, Switch, Redirect } from "react-router-dom";
import GlobalPage from "./pages/GlobalPage";
import TokenPage from "./pages/TokenPage";
import PairPage from "./pages/PairPage";
import { useGlobalData, useGlobalChartData } from "./contexts/GlobalData";
import { isAddress } from "./utils";
import AllTokensPage from "./pages/AllTokensPage";
import AllPairsPage from "./pages/AllPairsPage";
import FeesPage from "./pages/FeesPage";
import AdminPage from "./pages/AdminPage";
import IcePage from "./pages/IcePage";
import PinnedData from "./components/PinnedData";

import SideNav from "./components/SideNav";
import LocalLoader from "./components/LocalLoader";
import { useLatestBlocks } from "./contexts/Application";
import GoogleAnalyticsReporter from "./components/analytics/GoogleAnalyticsReporter";
import { PAIR_BLACKLIST, TOKEN_BLACKLIST } from "./constants";
import { GRAPH_CLIENTS } from "./apollo/client";
import IglooBg from "../src/assets/igloo13.jpg";

const AppWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ open }) =>
    open ? "220px 1fr 200px" : "220px 1fr 64px"};

  @media screen and (max-width: 1400px) {
    grid-template-columns: 220px 1fr;
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
    max-width: 100vw;
    overflow: hidden;
    grid-gap: 0;
  }
`;

const Right = styled.div`
  position: fixed;
  right: 0;
  bottom: 0rem;
  z-index: 99;
  width: ${({ open }) => (open ? "220px" : "64px")};
  height: ${({ open }) => (open ? "fit-content" : "64px")};
  overflow: auto;
  background-color: #0b1221;
  @media screen and (max-width: 1400px) {
    display: none;
  }
`;

const Center = styled.div`
  height: 100%;
  z-index: 9999;
  transition: width 0.25s ease;
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WarningBanner = styled.div`
  background-color: #ff6978;
  padding: 1.5rem;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`;

const Background = styled.div`
  height: 100vh;
  min-height: 800px;
  width: 100%;
  background-image: url(${IglooBg});
  background-size: cover;
  position: fixed;
  opacity: 0.5;
`;

/**
 * Wrap the component with the header and sidebar pinned tab
 */
const LayoutWrapper = ({ children, savedOpen, setSavedOpen }) => {
  return (
    <>
      <ContentWrapper open={savedOpen}>
        <Background style={{ backgroundImage: `url${IglooBg}` }} />
        <SideNav />
        <Center id="center">{children}</Center>
      </ContentWrapper>
    </>
  );
};

const BLOCK_DIFFERENCE_THRESHOLD = 600;

function App() {
  const [savedOpen, setSavedOpen] = useState(false);

  const globalData = useGlobalData();
  const globalChartData = useGlobalChartData();
  // const [latestBlock, headBlock] = useLatestBlocks();

  // const showWarning =
  //   headBlock && latestBlock
  //     ? headBlock - latestBlock > BLOCK_DIFFERENCE_THRESHOLD
  //     : false;

  return (
    <AppWrapper>
      
        {/* {showWarning && (
        <WarningWrapper>
          <WarningBanner>
            {`Warning: The data on this site has only synced to block ${latestBlock} (out of ${headBlock}). Please check back soon.`}
          </WarningBanner>
        </WarningWrapper>
      )} */}
        {globalData &&
        Object.keys(globalData).length > 0 &&
        globalChartData &&
        Object.keys(globalChartData).length > 0 ? (
          <>
            <Route path="/" element={<Redirect to={"/home/mantle"} />}></Route>
            <Route component={GoogleAnalyticsReporter} />
            <Switch>
              <Route
                exacts
                strict
                path="/token/:version/:tokenAddress"
                render={({ match }) => {
                  if (
                    isAddress(match.params.tokenAddress.toLowerCase()) &&
                    !Object.keys(TOKEN_BLACKLIST).includes(
                      match.params.tokenAddress.toLowerCase()
                    )
                  ) {
                    return (
                      <LayoutWrapper
                        savedOpen={savedOpen}
                        setSavedOpen={setSavedOpen}
                      >
                        <TokenPage
                          address={match.params.tokenAddress.toLowerCase()}
                        />
                      </LayoutWrapper>
                    );
                  } else {
                    return <Redirect to="/home/mantle" />;
                  }
                }}
              />
              <Route
                exacts
                strict
                path="/pair/:version/:pairAddress"
                render={({ match }) => {
                  if (
                    isAddress(match.params.pairAddress.toLowerCase()) &&
                    !Object.keys(PAIR_BLACKLIST).includes(
                      match.params.pairAddress.toLowerCase()
                    )
                  ) {
                    return (
                      <LayoutWrapper
                        savedOpen={savedOpen}
                        setSavedOpen={setSavedOpen}
                      >
                        <PairPage
                          pairAddress={match.params.pairAddress.toLowerCase()}
                        />
                      </LayoutWrapper>
                    );
                  } else {
                    return <Redirect to="/home/mantle" />;
                  }
                }}
              />

              <Route path="/home/:version">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <GlobalPage />
                </LayoutWrapper>
              </Route>

              <Route path="/tokens/:version">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <AllTokensPage />
                </LayoutWrapper>
              </Route>

              <Route path="/pairs/:version">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <AllPairsPage />
                </LayoutWrapper>
              </Route>

              <Route path="/fees/:version">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <FeesPage />
                </LayoutWrapper>
              </Route>

              <Route path="/ice/:version">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <IcePage />
                </LayoutWrapper>
              </Route>

              <Route path="/admin/:version">
                <LayoutWrapper
                  savedOpen={savedOpen}
                  setSavedOpen={setSavedOpen}
                >
                  <AdminPage />
                </LayoutWrapper>
              </Route>

              <Redirect to="/home/mantle" />
            </Switch>
          </>
        ) : (
          <LocalLoader fill="true" />
        )}
    </AppWrapper>
  );
}

export default App;
