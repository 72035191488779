import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Box } from "rebass";
import styled from "styled-components";

import { AutoRow, RowBetween } from "../components/Row";
import { AutoColumn } from "../components/Column";
import PairList from "../components/PairList";
import TopTokenList from "../components/TokenList";
import TxnList from "../components/TxnList";
import GlobalChart from "../components/GlobalChart";
import Search from "../components/Search";
import GlobalStats from "../components/GlobalStats";

import { useGlobalData, useGlobalTransactions } from "../contexts/GlobalData";
import { useAllPairData } from "../contexts/PairData";
import { useMedia } from "react-use";
import Panel from "../components/Panel";
import { useAllTokenData } from "../contexts/TokenData";
import { formattedNum, formattedPercent } from "../utils";
import { TYPE, ThemedBackground } from "../Theme";
import { CustomLink } from "../components/Link";

import { PageWrapper, ContentWrapper } from "../components";
import CheckBox from "../components/Checkbox";
import QuestionHelper from "../components/QuestionHelper";
import { useVersion } from "../contexts/Application";

const ListOptions = styled(AutoRow)`
  height: 40px;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;

  @media screen and (max-width: 640px) {
    font-size: 1rem;
  }
`;

const GridRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 6px;
  align-items: start;
  justify-content: space-between;
`;

function AdminPage() {
  // get data for lists and totals
  const allPairs = useAllPairData();
  const allTokens = useAllTokenData();
  const transactions = useGlobalTransactions();
  const { oneDayFeeUSD, oneDayFeeChange } = useGlobalData();

  const { versionLabel } = useVersion();

  // breakpoints
  const below800 = useMedia("(max-width: 800px)");

  // scrolling refs
  useEffect(() => {
    document.querySelector("body").scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);

  // for tracked data on pairs
  const [useTracked, setUseTracked] = useState(true);

  return (
    <PageWrapper>
      <ThemedBackground backgroundColor={"#0b1221"} />
      <ContentWrapper>
        <div>
          <AutoColumn
            gap="24px"
            style={{ paddingBottom: below800 ? "0" : "24px" }}
          >
            <TYPE.largeHeader>
              {below800 ? "Admin Panal" : "Admin Panal"}
            </TYPE.largeHeader>
            <Search />
            <GlobalStats />
          </AutoColumn>
          <div>
            {/* Content goes here */}
          </div>
        </div>
      </ContentWrapper>
    </PageWrapper>
  );
}

export default withRouter(AdminPage);
